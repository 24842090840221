// Copyright 2024 Immersive Technologies Pty Ltd. All rights reserved.

import { t } from '../../i18n/i18n';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { logOut } from '../../utils/authServiceUtils';
import { LoadingButton } from '@mui/lab';
import { useState } from 'react';
import { ErrorCode, getErrorCode } from '../../utils/errorUtils';

/**
 * SwitchAccountButton Component
 *
 * This component renders a button that allows the user to switch accounts.
 * When the button is clicked, the user is redirected to the login page with the current query parameters (if any).
 *
 * @param {string} color - The color of the button. Default is 'primary'.
 * @returns {JSX.Element} - The SwitchAccountButton component.
 */
export const SwitchAccountButton = ({
  color = 'primary', // Default value for color is set to 'primary'
}: {
  color?: 'primary' | 'inherit' | 'secondary' | 'success' | 'error' | 'info' | 'warning' | undefined;
  handleClick?: () => void;
}) => {
  const [isLoading, setLoading] = useState(false);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  // Redirect users back to the login page if they want to switch account
  const onClickSwitchAccount = async () => {
    setLoading(true);

    try {
      // Log out the user and clear the session
      await logOut();
    } catch (err) {
      const errorCode = getErrorCode(err);
      // If the session is already invalid, ignore the error and redirect the user to the login page
      if (errorCode !== ErrorCode.SessionExpired) {
        throw err;
      }
    }

    if (window.location.pathname === '/login') {
      window.location.reload();
    } else {
      navigate({ pathname: '/login', search: searchParams.toString() });
    }
  };

  return (
    <LoadingButton variant="contained" color={color} onClick={onClickSwitchAccount} loading={isLoading}>
      {t({
        defaultMessage: 'Log in with a different account',
        id: 'ftt8US',
      })}
    </LoadingButton>
  );
};
